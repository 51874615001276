export const data = {
  en: {
    text: 'Decentralized Reputation & Justice System for Web3 and Real World Issues',
    list: [
      'Current Version: Alpha 0.5.0 (Private)'
    ]
  },
  ru: {
    text: 'Decentralized Reputation & Justice System for Web3 and Real World Issues',
    list: [
      'Текущая версия: Alpha 0.5.0 (Частная)'
    ]
  }
}
