export const data = {
  header: {
    title: {
      en: 'About us',
      ru: 'О нас'
    },
    text: {
      en: 'Humans sharing the vision of a fairer world',
      ru: 'Humans sharing the vision of a fairer world'
    }
  }
}
